.navbar {
    padding: 10px 16px;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;

    box-shadow: 0 5px 20px 0 rgba(158, 167, 179, 0.05);
    background-color: #fff;
    z-index: 100;
    overflow:hidden;
}
.navbar-container {
    display: flex;
    justify-content: space-between;
    height: 40px;
    align-items: center;
}

.navbar-name {
    margin-left: 7%;
    color: #006ff5;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    font-family: Helvetica, sans-serif;
    font-weight: bold;
    align-items: center;
    height: 100%;
}

.img-cover {
    height: 100%; width: auto;
  }

.vama-page-download-button {
    display: table;
    text-align: center;
    vertical-align: middle;

    background: #0071EE;
    border-radius: 8px;
    font-weight: bold;
    font-size: 15px;
    color: #FFFFFF;
    height: 100%;
    /*aspect-ratio: 130/42;*/
    padding: 8px 16px;
    
    font-family: Inter, sans-serif;
    margin-right: 3%;
}

.inner-download {
    display: table-cell;
    vertical-align: middle;
    text-transform: uppercase;
}

@media screen and (max-width: 520px) {
    .navbar-container {
        height: 30px;
    }

    .vama-page-download-button {
        font-size: 10px;
    }
}

@supports not (aspect-ratio: 130 / 42) {
    .vama-page-download-button {
      padding-left: 15px;
      padding-right: 15px;
    }

  }