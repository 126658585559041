@font-face {
    font-family: 'Inter-Regular';
    src: local('Inter-Regular'), url(./assets/fonts/Inter-Regular.ttf) format('ttf');
}

@font-face {
    font-family: 'Futura-Medium';
    src: url(./assets/fonts/Futura-Medium.ttf) format('truetype');
}

* {
    box-sizing: border-box;
}