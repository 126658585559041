html, body {
    background-color: #f2f2f7;
}

.post-container {
    width: 50%;
    max-width: 400px;
    margin: 35px auto;
    margin-bottom: 70px;
    background-color: white;
    border-radius: 10px;
    padding-bottom: 16px;
    padding-top: 16px;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    -webkit-font-smoothing: antialiased;
}

.creator-container {
    display: flex;
    align-content: center;
    justify-content: left;
    font-size: 16px;
    margin-bottom: 16px;
    color: #1c1c1e;
}

.creator-initials-avatar {
    --color: #A071EE;
    --white-color: #A1A4DB; 
    font-size: 16pt;
    line-height: 40px;
    
    height: 40px; width: 40px;
    border-radius: 50%;
    background-image: linear-gradient(var(--white-color), var(--color));
    text-align: center;
    color: #ffffff;
    margin: 0 16px;
}

.creatorAvatar {
    object-fit: cover;
    
    height: 40px; width: 40px;
    border-radius: 50%;
    background-image: linear-gradient(var(--white-color), var(--color));
    text-align: center;
    color: #ffffff;
    margin: 0 16px;
    cursor: pointer
}

#creator-name {
    font-size: 16px;
    font-weight: 600;
    color: #1c1c1e;
}

.timeAgo {  
    color: #c7c7cc;
}

.postTextContent {
    margin-left: 16px;
    font-size: 16px;
    color: #1c1c1e
}

#post-image-content {
    display: block;
    margin: 16px auto;
    width: 80%;
}

#conversation-container {
    position: relative;
}

#blurry-messages {
    display: block;
    margin: 16px auto;
    width: 90%;
}

#blurry-messages-overlay {
    display: flex;

    left: 50%;
    top: 40%;
    position: absolute; 
    z-index: 2;
    width: 90%;
    height: 100%;
    padding: 15px;
    margin-top: 15px;

    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: bold;
    border-radius: 5px;
    background-color: rgba(31, 16, 16, 0.7);
    color: #fff;
}

.interaction-bar-container {
    display: flex;
    align-content: center;
    justify-content: space-around;
    font-size: 15px;
    color: #1c1c1e;
    margin-top: 16px;
}

.vote-cell {
    display: inline-flex;
    align-items: center;
}

#upvote {
    margin-left: 8px;
}

#downvote {
    margin-right: 8px;
    transform: rotate(180deg);
}

.comments-cell {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

#comment-bubble {
    margin-right: 10px;
}

#view-in-vama-footer {
    display: flex;

    position: -webkit-sticky;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: rgb(250, 250, 250);
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
    border-radius: 10px;
    border-style: solid;
    border-color: rgb(220, 216, 216);
    border-width: 1px;

    max-width: 500px;
    margin: 0 auto;
}

#view-in-vama-button {
    display: table;
    vertical-align: middle;
    text-align: center;

    font-size: 14px;
    font-weight: bold;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    color: white;

    background: #0071EE;
    border-radius: 22px;

    height: 100%;
    margin: 8px auto;
    padding: 12px 24px;

    cursor: pointer;
}

#inner-view-in-vama {
    display: table-cell; 
    vertical-align: middle;    
}

@media screen and (max-width: 520px) {
    #body {
        position: fixed;
    }
    .post-container {
        margin-top: 20px;
        width: 95%;
        max-width: none;
    }

    #post-image-content {
        max-width: 80%;
    }

    .timeAgo {
        font-size: 14px;
    }

    #creator-name {
        font-size: 14px;
        font-weight: 500;
    }

    #view-in-vama-footer{
        width: 100%;
        max-width: none;
        border-radius: 0px;
    }

    #view-in-vama-button {
        font-size: 12px;
    }

}